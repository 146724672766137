<template>
  <div class="wrap">
    <p class="title">资源管理</p>
    <!-- 搜索 -->
    <a-form layout="inline">
      <a-form-item label="资源名称">
        <a-input v-model:value.trim="title" placeholder="请输入用户名称" size="small" allowClear></a-input>
      </a-form-item>
      <a-form-item label="资源类型">
        <!-- <a-select style="width: 150px;" v-model:value="type" placeholder="请选择资源类型" size="small" allowClear>
          <a-select-option v-for="item in resourceTypeOptions" :value="item.id" :key="item.id">
            {{ item.resourceType }}
          </a-select-option>
        </a-select> -->
        <a-tree-select
          v-model:value="type"
          show-search
          style="width: 100%"
          :replaceFields="replaceFields"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="resourceTypeOptions"
          placeholder="请选择资源类型"
          allow-clear
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-item>
      <a-form-item class="btn-group">
        <a-button type="primary" size="small" @click="search">查询</a-button>
        <a-popconfirm
          title="你确定要点嘛？会影响系统"
          ok-text="确定"
          cancel-text="取消"
          @confirm="confirm"
          @cancel="cancel"
        >
          <a-button type="primary" size="small" :disabled="isVif" >同步海康视频网关</a-button>
        </a-popconfirm>
        <a-button size="small" @click="reset">重置</a-button>
      </a-form-item>
    </a-form>
    <!-- 表格 -->
    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :pagination="{ pageSize: pageSize, current: curPage, total: total }"
      :row-key="record => record.resourceId"
      @change="handlePageChange"
    >
      <template #assignedRole="row">
        <span>{{ row.record.roleName.join(' / ') }}</span>
      </template>
      <template #action="row">
        <div class="action">
          <span class="edit" @click="showEditModal(row)">编辑</span>
          <span class="del" @click="del(row)">删除</span>
        </div>
      </template>
    </a-table>
  </div>
  <!-- 新增 / 编辑 用户弹窗 -->
  <edit-resource-modal ref="EditUserRef" @updateSuccess="search"></edit-resource-modal>
</template>

<script>
import { defineComponent, createVNode, ref, toRefs, reactive, onMounted } from 'vue';
import api from '@/services';
import { Modal, message } from 'ant-design-vue';
import constant from '@/utils/common';
import EditResourceModal from './EditResourceModal';

// 替换数组建原有字段
const replaceFields = {
  children: 'children',
  title: 'resourceName',
  value: 'key',
  key: 'key'
};

const columns = [
  {
    title: '资源类型',
    dataIndex: 'resourceType',
    align: 'center',
    ellipsis: true
  },
  {
    title: '资源名称',
    dataIndex: 'title',
    align: 'center',
    ellipsis: true
  },

  {
    title: '已分配角色',
    dataIndex: 'roleName',
    align: 'center',
    ellipsis: true,
    slots: {
      customRender: 'assignedRole'
    }
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    width: 200,
    align: 'center',
    slots: {
      customRender: 'action'
    }
  }
];
export default defineComponent({
  components: {
    EditResourceModal
  },
  setup() {
    onMounted(() => {
      getResourceList();
      getRoleResourceTreeData();
    });
    let userinfo = JSON.parse(localStorage.getItem('SS_userinfo'))
    let isVif = ref(true)
    if(userinfo?.nickName == '姚纯') {
      isVif.value = false
    }
    
    const EditUserRef = ref();

    const addEditVisible = ref(false); // 控制 新增 & 编辑 用户弹窗显隐

    // table 相关变量
    const tableState = reactive({
      pageSize: 8,
      curPage: 1,
      total: 0,
      dataSource: [],
      columns
    });

    // 条件筛选表单
    const formState = reactive({
      type: null,
      title: '',
      roleName: '',
      resourceTypeOptions: []
    });

    // 获取用户列表 table data
    const getResourceList = async () => {
      const params = {
        ...formState,
        curPage: tableState.curPage,
        pageSize: tableState.pageSize
      };
      delete params.resourceTypeOptions;
      const { data, success, msg } = await api.getResourceList(params);
      if (success) {
        tableState.dataSource = data.records;
        tableState.total = data.total;
      } else {
        message.error(msg);
      }
    };

    // 查询资源信息 树状结构
    const getRoleResourceTreeData = async () => {
      const { success, data } = await api.getRoleResourceTreeDataById();
      if (success) {
        formState.resourceTypeOptions = data;
      }
    };

    const handlePageChange = page => {
      tableState.curPage = page.current;
      getResourceList();
    };

    // 显示新增/编辑用户弹窗
    const showEditModal = e => {
      EditUserRef.value.showModal(e.record);
    };

    // 删除
    const del = row => {
      Modal.confirm({
        title: '',
        centered: true,
        content: createVNode(
          'div',
          {
            style: 'text-align: center;font-size: 18px;font-weight: bold;'
          },
          `确认要删除资源 ${row.record.title} 吗？`
        ),

        async onOk() {
          console.log('row===', row.record);
          let params = {
            resourceId: row.record.resourceId,
            productionId: row.record.productionId
          };
          let { success, msg } = await api.delResourceById(params);
          if (success) {
            message.success('删除成功！');
            getResourceList();
          } else {
            message.error(msg);
          }
        }
      });
    };

    const search = () => {
      tableState.curPage = 1;
      getResourceList();
    };

    const reset = () => {
      tableState.curPage = 1;
      Object.keys(formState).map((key) => {
        if (key == 'type') {
          formState[key] = null
        }
        else if(key != 'resourceTypeOptions') {
          formState[key] = ''
        }
      });
      getResourceList();
    };

    const confirm = async () => {
      await api.synchronous();
    }
    const cancel = () => {
    }
    
    return {
      replaceFields,
      EditUserRef,
      addEditVisible,
      ...toRefs(formState),
      getResourceList,
      handlePageChange,
      showEditModal,
      search,
      reset,
      del,
      ...toRefs(tableState),
      confirm,
      cancel,
      isVif
    };
  }
});
</script>

<style lang="less" scoped>
.wrap {
  padding: 15px 20px;
  flex-wrap: wrap;
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  :deep(.ant-form) {
    display: flex;
    justify-content: center;
    margin: 15px 0;
    .btn-group {
      flex: 1;
      .ant-btn {
        margin-left: 20px;
      }
    }
  }
  :deep(.ant-table-wrapper) {
    margin-top: 15px;
    .ant-table {
      .avatar {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
      .status-tag,
      .action > span {
        display: inline-block;
        padding: 0px 10px;
        border-radius: 3px;
        font-size: 12px;
        height: 25px;
        line-height: 25px;
      }
      .status-tag {
        background-color: #4acc90;
        color: #fff;
      }

      .action {
        span {
          cursor: pointer;
          color: #fff;
        }
        .edit {
          background-color: #007fff;
          margin-right: 14px;
        }
        .del {
          background-color: #d71345;
        }
      }
    }
  }
}
</style>
