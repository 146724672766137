<template>
  <div ref="ModalBoxRef">
    <a-modal
      v-model:visible="visible"
      :onCancel="handleCancel"
      @ok="handleOk"
      :getContainer="() => ModalBoxRef"
      centered
    >
      <p class="title">编辑资源</p>
      <a-divider />
      <template #footer>
        <a-button key="back" @click="handleCancel">取消</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="handleOk">保存</a-button>
      </template>
      <a-form :label-col="labelCol" :wrapper-col="wrapperCol" :model="formState">
        <a-form-item label="资源名称">
          <a-input v-model:value.trim="formState.title" placeholder="请输入资源名称" allowClear></a-input>
        </a-form-item>
        <a-form-item label="资源类型">
          <a-input v-model:value.trim="formState.resourceType" disabled></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent, ref, reactive, nextTick } from 'vue';
import { message } from 'ant-design-vue';

import api from '@/services';

export default defineComponent({
  setup(props, context) {
    const loading = ref(false);
    const visible = ref(false);

    const ModalBoxRef = ref();

    const labelCol = reactive({ span: 4, offset: 3 });
    const wrapperCol = reactive({ span: 14 });
    const formState = reactive({
      title: '',
      resourceType: '',
      productionId: ''
    });

    const showModal = rowData => {
      visible.value = true;
      nextTick(() => {
        Object.keys(formState).map(key => {
          formState[key] = rowData[key];
        });
        console.log('rowData====', rowData);
      });
    };

    const handleOk = async () => {
      loading.value = true;
      let params = {
        ...formState
      };
      delete params.resourceType;
      const { success } = await api.updateResource(params);
      if (success) {
        message.success('编辑成功！');
        context.emit('updateSuccess');
      }
      loading.value = false;
      visible.value = false;
    };

    const handleCancel = () => {
      visible.value = false;
      clearForm();
    };

    const clearForm = () => {
      Object.keys(formState).map(key => {
        formState[key] = '';
      });
    };

    return {
      labelCol,
      wrapperCol,
      formState,
      loading,
      visible,
      showModal,
      handleOk,
      handleCancel,
      ModalBoxRef,
      clearForm
    };
  }
});
</script>

<style lang="less" scoped>
:deep(.ant-modal-body) {
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
}
</style>
