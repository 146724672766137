let constant = {};

// echarts 图表配色集合
constant.color = [
  '#46b3ce',
  '#6e72e1',
  '#02b5d8',
  '#3b76ff',
  '#47e3d0',
  '#a39cd8',
  '#83a7e8',
  '#63c2df',
  '#90e0ef',
  '#9fe3ad',
  '#ff946f'
];

// 用户管理 => 角色管理 => 新增 / 编辑 角色 => 资源分配类型
constant.resourceTypeList = [
  {
    resourceType: '回看',
    id: 2
  },
  {
    resourceType: '视频上传',
    id: 3
  },
  {
    resourceType: '无人机',
    id: 4
  }
];

// 直播管理 => 直播设置 => 时效列表
constant.unitList = [
  {
    label: '小时',
    value: 1
  },
  {
    label: '天',
    value: 2
  },
  {
    label: '周',
    value: 3
  }
];

// 直播管理 => 直播设置 => 强制关闭时间单位列表
constant.closeUnitList = [
  {
    label: '小时',
    value: 1
  }
];

// 直播管理 => 直播设置 => 踢出时间单位列表
constant.kickUnitList = [
  {
    label: '分钟',
    value: 1
  },
  {
    label: '小时',
    value: 2
  }
];

// 直播管理 => 直播设置 => 时效列表
constant.resolutionList = [
  {
    label: '720P',
    value: 1
  },
  {
    label: '1080P',
    value: 2
  },
  {
    label: '2K',
    value: 3
  },
  {
    label: '4K',
    value: 4
  }
];

export default constant;
